import type { Environment } from 'types/environment'
import type { ShippingMethodName } from 'types/cart'
import type { CountryCode } from 'types/generic/geographic'
import type { Section } from 'types/models/section'

// https://docs.brinkcommerce.com/models

// TODO: Merge CartItem & ShippingOption as they're both products
interface sizeFilterValue {
  value: '36-40'
  key: 'size'
}
type possibleFilterValues =
  | {
    key: string
    value: string
  }
  | sizeFilterValue

type marketCodes = 'NLWHS01M'

export interface Discounts {
  rules: {
    id: string // "discount-code-id"
    ruleData: {
      discountCode: string // "summer2020"
    }
    ruleType: 'DISCOUNTCODE' | string
  }[]
  orderRules: {
    [key: string]: any
    freeShipping: boolean
  }[]
  productDiscounts: any[]
  cartDiscount: {
    [key: string]: number
  }
}
export interface CartItem {
  id: string
  productId: string
  rowId: string
  quantity: number
  addons: []
  added: Date
  updated: Date
  relatedProduct: string // This is the parent id
  attribute: Record<string, unknown>
  customerAttribute: {
    ean?: string
    upc?: string
    size: string
    skuNumber: string
    channelStatus: string | 'enabled' | 'disabled'
    variantCode: string
    activeOnMarket: marketCodes[]
    // To be verified attributes
    itemComposition?: {
      [key in Environment.StorefrontCode]: string
    }
    itemType: 'Global'
    itemProductFeedLabel: string
    itemFBA: null
    itemSections: Section[]
  }
  name: string
  category: 'Socks' | 'All Products'
  slug: string
  parentSlug: string
  type: 'productVariant' | 'shippingOption'
  // currency: string
  price: {
    [key in Environment.Currency['code']]?: number
  }
  discount: {
    [key in Environment.Currency['code']]?: number
  }
  isGift: boolean
  // Sizes, colors, etc
  filterAttributes: possibleFilterValues[]
  options: []
  imageUrl: string | null
}
export interface Cart {
  id?: string
  state?: 'ACTIVE' | 'CLOSED'
  created?: Date
  lastUpdated?: Date
  cartItems: CartItem[]
  totalPriceWithDiscount: number
  totalDiscountAmount: number
  totalPrice: number
  discounts: Discounts | null | Record<string, unknown>
  isFreeShippingDiscountCodeApplied: boolean // TODO - S Is this a lie?
  store: {
    countryCode?: Uppercase<CountryCode>
    currencyUnit?: Environment.Currency['code']
    languageCode?: string
    taxPercentage?: number
  }
}

export interface getCartResponse extends Cart {
  totalShippingAmount: number
}

export interface Error {
  error: string
  requestId: string
  code: string | keyof typeof BrinkPaymentErrorCodes
  message: string
}

export interface CartSessionResponse {
  jwtToken: string
  cart: Cart
}

/**
 * {@link https://docs.brinkcommerce.com/integrations#post-paymentsadyenpaymentmethods}
 */
export interface PaymentMethodsRequestBody {
  amount: {
    currency: string
    value: number
  }
  countryCode: Uppercase<CountryCode>
  channel: 'ios' | 'android' | 'web'
  blockedPaymentMethods: string[]
  languageCode: string
  shopperReference?: string // Required for recurring payments, example user ID or account ID.
}

/**
 * {@link https://docs.brinkcommerce.com/integrations#adyen-sessions}
 *
 * {@link http://swagger.brinkcommerce.com.s3-website-eu-west-1.amazonaws.com/order/#/default/post_carts_adyen_orders}
 */
export interface AdyenSessionRequestBody {
  shippingAddress: Address
  billingAddress: Address
  returnUrl: string

  storePaymentMethod?: true
  shopperReference?: string
  email: string
  languageCode?: string
  blockedPaymentMethods?: [string]
}

/**
 * {@link https://docs.brinkcommerce.com/integrations#adyen-sessions}
 */
export interface AdyenSessionResponse {
  adyen: {
    session: AdyenSession
  }
  brink: {
    order: Order
  }
}

export interface AdyenSession {
  id: string
  sessionData: string
}

export interface ShippingOption {
  active: number
  archived: number
  attribute: {
    // !Change with locale codes
    description?: {
      [key: string]: string
    }
    shortdescription?: {
      [key: string]: string
    }
    fulldescription?: {
      [key: string]: string
    }
    displayName?: {
      [key: string]: string
    }
  }
  category: 'shipping'
  created: Date
  customerAttributes: []
  description: string
  discount: { [key in Environment.Currency['code']]?: number }
  filterAttributes: []
  id: ShippingMethodName
  imageUrl: string
  images: string[]
  lastUpdated: Date
  name: string
  parentSlug: string
  price: {
    [key: string]: number
  }
  relatedProduct: string
  sequenceNumber: number
  slug: string
  type: 'shippingOption'
}

export interface ProductStock {
  storeId: Uppercase<Environment.StorefrontCode>
  products: {
    id: string
    validateStock: boolean
    stockQuantity: number
    reservedQuantity: number
    availableQuantity: number
    isAvailable: boolean
  }[]
}

export interface Address {
  country: Environment.CountryCode
  city: string
  phone: string
  streetAddress: string
  houseNumberOrName: string
  familyName: string
  postalCode: string
  givenName: string
  region: string
}

export interface Store {
  countryCode: Uppercase<CountryCode>
  languageCode: string
  currencyUnit: string
  created: string
  lastUpdated: string
  tax: number
  continent?: 'EU' | 'OC' | 'AS' | 'NA' | 'SA' | 'AF' | 'AN'
}
export interface OrderLine {
  id: string
  productId: string
  name: string
  options: any[]
  isGift: boolean
  quantity: number
  taxRate: number
  totalAmount: number
  totalAmountWithDiscount: number
  totalTaxAmount: number
  type: OrderLineType
  price: number
  promotionPrice: number
  externalTaxRate?: number
}
export interface Order {
  id: string
  cartId: string
  reference: number
  orderLines: OrderLine[]
  billingAddress: Address
  shippingAddress: Address
  orderAmount: number
  orderAmountWithDiscount: number // Total?
  orderTaxAmount: number
  orderDiscountAmount: number
  store: string
  countryCode: string
  languageCode: string
  currencyUnit: Environment.Currency['code']
  email: string
  orderRules: any[]
  paymentProviderAccount: string
  paymentProvider?: string
  paymentMethod?: string
  statusLog: StatusLog[]
  status?: 'success' | 'created' | string
  potentialFraud?: boolean
  paymentReference?: string
  originalPaymentReference?: string
  lastUpdated?: string
  created?: string
  personId?: string
  refundActive?: string
  taxIncludedPricing: boolean
  externalTaxRate?: number
  customerAttribute: {
    taxCalculations: TaxCalculations
  }
  failedReason?: string
}

export enum BrinkCartErrorCodes {
  UNAUTHORIZED = 'UNAUTHORIZED',
  CART_NOT_FOUND = 'CART_NOT_FOUND',
  CART_CLOSED = 'CART_CLOSED',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  MISSING_PRODUCTS = 'MISSING_PRODUCTS', // "error": "Product(s) not found: 7333102442632, 7333102606874, 7333102608397",
}

export enum BrinkPaymentErrorCodes {
  UNAUTHORIZED = 'UNAUTHORIZED',
  MISSING_SHIPPING_OPTION_ERROR = 'MISSING_SHIPPING_OPTION_ERROR',
  TAX_JAR_ERROR = 'TAX_JAR_ERROR',
}

export enum BrinkConfirmOrderFailedReasons {
  CVC_DECLINED = 'CVC Declined',
}

export function isValidStore(store: any): store is Store {
  return ['countryCode', 'languageCode', 'currencyUnit', 'created', 'lastUpdated', 'tax'].every(key =>
    Reflect.has(store, key),
  )
}

type OrderLineType = 'physical' | 'shipment' | 'addon'

interface StatusLog {
  status: string
  timestamp: Date
}

interface TaxAmount {
  amount: number
  currency: string
  precision: number
}

interface TaxItem {
  id: string
  totalTaxAmount: TaxAmount
  taxRate: number
}

interface TaxShipping {
  id: string
  totalTaxAmount: TaxAmount
  taxRate: number
}

interface TaxSource {
  name: string
}

interface TaxCalculations {
  orderTaxAmount: TaxAmount
  taxRate: number
  items: TaxItem[]
  shipping: TaxShipping
  taxSource: TaxSource
}
