import Axios from 'axios'
import { BRINK_API_ORDER_URL } from 'configuration/global.configuration'
import type { PaymentRepository } from 'contracts/PaymentRepositoryContract'
import { convertToBrinkAddress, normalizeOrder } from 'lib/brink'
import type * as Brink from 'types/vendors/brink'

const BLOCKED_PAYMENT_METHODS: string[] = []

export const BrinkPaymentRepository: PaymentRepository = ({ baseURL, piniaInstance }) => {
  const cartStore = useCartStore(piniaInstance)
  const repository: ReturnType<PaymentRepository> = {
    _name: 'BrinkPaymentRepository ',
    normalizers: {},
    recentRequests: [],
    api: Axios.create({
      baseURL,
    }),

    async createPaymentSession({
      shippingAddress,
      billingAddress,
      contact,
      languageCode,
      returnUrl,
    }) {
      try {
        const { data } = await this.api.post<Brink.AdyenSessionResponse>(
          `/${BRINK_API_ORDER_URL}/carts/adyen/orders`,
          {
            blockedPaymentMethods: BLOCKED_PAYMENT_METHODS,
            shippingAddress: convertToBrinkAddress(shippingAddress, contact),
            billingAddress: convertToBrinkAddress(billingAddress, contact),
            email: contact.email,
            languageCode,
            returnUrl,
          } as Brink.AdyenSessionRequestBody,
        )

        const cartStore = useCartStore()
        const shippingStore = useShippingStore()

        return {
          order: normalizeOrder(data.brink.order, cartStore.state, shippingStore.selected),
          session: data.adyen.session,
        }
      }
      catch (error) {
        if (!Axios.isAxiosError(error))
          return Promise.reject(Error(`Not axios error${error}`))
        if (error.response)
          return Promise.reject(error.response.data)

        return Promise.reject(error)
      }
    },

    async confirmPaymentSession(signature, orderId) {
      try {
        const { data: order } = await Axios.get<Brink.Order>(
          `${baseURL}/${BRINK_API_ORDER_URL}/payments/adyen/orders/${orderId}/confirmation`,
          {
            headers: {
              authorization: signature,
            },
          },
        )

        if (order.failedReason)
          return Promise.reject(order.failedReason)

        const cartStore = useCartStore()
        const shippingStore = useShippingStore()

        // TODO: Does this work?
        return normalizeOrder(order, cartStore.state, shippingStore.selected)
      }
      catch (e) {
        return Promise.reject(e)
      }
    },
  }

  repository.api.interceptors.request.use((config) => {
    if (process.client)
      config.headers.Authorization = cartStore.cartToken

    repository.recentRequests.push({
      method: config.method?.toUpperCase(),
      url: `${baseURL}${Axios.getUri(config)}`,
    })

    return config
  })

  return repository
}
