import { z } from 'zod'
import { getQuery as getQueryServer, getRouterParams } from 'h3'
import type { RouteParams } from 'vue-router'
import {
  cleanDoubleSlashes,
  getQuery as getQueryClient,
  withQuery,
  withoutTrailingSlash,
} from 'ufo'

import type { Route } from 'types/route'
import type { CheckoutView } from 'types/checkout'
import type * as Helpers from 'types/helpers'
import { stripTrailingSlashes } from './strings'
import { url } from './url/index'

import type { NuxtSSRContext } from '#app'
import type { RouteLocationNormalized } from '#vue-router'

export function pathFromRoute(route: Route): string {
  const transformed = route.fullPath.slice(1).replace(/\/$/, '')
  return transformed.split('/checkout').shift() || transformed
}

interface Meta {
  [key: string]: string | boolean | undefined
  ['isCheckoutPage']?: true
  ['context']?: string
  ['section']?: string
  ['views']?: CheckoutView
}

export function parseMeta(route?: Route): Meta {
  if (!route)
    return {}

  if (Array.isArray(route.meta)) {
    let meta: { [key: string]: string } = {}

    route.meta?.forEach((metaValues: { [key: string]: string }) => {
      meta = { ...metaValues, ...meta }
    })

    return meta as Meta
  }
  else if (route.meta) {
    return route.meta as Meta
  }

  return {} as Meta
}

export function productUrl(sku: string) {
  return url(`/product/${sku}`)
}

const ROUTE_TYPES = [
  'index',
  'homepage',
  'category',
  'product',
  'search',
  'checkout-address',
  'checkout-cart',
  'checkout-payment',
  'checkout-success',
  'faq',
  'blog',
  'campaign',
  'unknown',
] as const

function isRouteType(type: string): type is (typeof ROUTE_TYPES)[number] {
  return ROUTE_TYPES.includes(type as any)
}

export function getRouteTypeFromURL(to: RouteLocationNormalized): (typeof ROUTE_TYPES)[number] {
  const [, routeType = ''] = to.path
    .split('/')
    .filter(Boolean)
    .filter(i => i !== '_default')

  const blogs = ['happy-news', 'happynews', 'happyblog']
  const faqs = ['faq', 'shipping']
  const nonCategories = ['about-us', ...faqs, ...blogs]
  const campaigns = ['corporate-gifts']

  // Initial language selection
  if (to.name === 'index')
    return 'index'

  // Homepage
  if (to.name === 'storefront')
    return 'homepage'

  // Differentiate checkout
  if (to.name === 'storefront-checkout-address')
    return 'checkout-address'
  if (to.name === 'storefront-checkout-cart')
    return 'checkout-cart'
  if (to.name === 'storefront-checkout-payment')
    return 'checkout-payment'
  if (to.name === 'storefront-checkout-success-id')
    return 'checkout-success'

  // "storefront-slugs" are all dynamic pages loaded from CMS.
  if (to.name === 'storefront-slugs') {
    // But we filter out known campaigns for now
    if (campaigns.includes(routeType))
      return 'campaign'

    // We expect any unknown routeTypes to be a category
    if (!nonCategories.includes(routeType))
      return 'category'
  }

  // Fix since on production shipping is not under FAQ
  if (faqs.includes(routeType))
    return 'faq'

  // Rename blog
  if (blogs.includes(routeType))
    return 'blog'

  return isRouteType(routeType) ? routeType : 'unknown'
}

export function getPathFromURL(url: string): string {
  return stripTrailingSlashes(url || '').split(/[?#]/)[0] || ''
}

export const checkoutUrl: Helpers.CheckoutUrlHelper = ({
  route,
  view,
  orderId,
}: {
  route: Route
  view: string
  orderId?: string
}) => {
  const suffix = view === 'success' ? `success/${orderId}` : view
  const {
    $currentStorefront: { storefrontCode },
  } = useNuxtApp()

  if (route.params.isError) {
    // uk/broken-link -> uk/checkout/cart
    // uk/product/abc-123 -> uk/checkout/cart
    const url = `/${storefrontCode}/checkout/${suffix || 'cart'}`

    if (route.query) {
      const query = getQueryClient(route.fullPath)
      return withQuery(url, query)
    }

    return url
  }
  else {
    const url = `/${storefrontCode}/checkout/${suffix || 'cart'}`

    if (route.query) {
      const query = getQueryClient(route.fullPath)
      return withQuery(url, query)
    }

    return url
  }
}

export function isRelativeUrl(url: string) {
  const { host } = new URL(url)
  return !host
}

export const QueryObject = z.record(z.union([z.string(), z.string().array()]))
export type QueryObject = z.infer<typeof QueryObject>

export function getQueryObject(ssrContext?: NuxtSSRContext): QueryObject {
  if (!ssrContext) {
    const route = useRoute()
    return QueryObject.parse(route.query)
  }

  const query = getQueryServer(ssrContext!.event)
  return QueryObject.parse(query)
}

export function getRouteParams(ssrContext?: NuxtSSRContext): QueryObject {
  if (!ssrContext) {
    const route = useRoute()
    return route.params
  }

  return getRouterParams(ssrContext!.event)
}

export function getRouteParam(params: RouteParams, key: string): string | undefined {
  const value = params[key]
  if (Array.isArray(value))
    return value.toString()
  else
    return value?.toString()
}

export function isHomePagePath(path: string, storefront: string) {
  const validPaths = [`/${storefront}`, `/${storefront}/`, '/', '']
  return validPaths.includes(path)
}
interface PaginationSEOLink {
  rel: 'next' | 'prev' | 'canonical'
  href: string
}

/**
 * Generates an array of SEO rel links: `prev`, `next` and `canonical`.
 */
export function generateSEOLinksFromPage({
  currentPage,
  totalPages,
  baseUrl,
  pathWithoutPage,
}: {
  currentPage: number
  totalPages: number
  baseUrl?: string
  pathWithoutPage: string
}): PaginationSEOLink[] {
  const notFound = currentPage < 1 || currentPage > totalPages

  if (notFound)
    return []

  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === totalPages
  const prevPagePath = `${pathWithoutPage}/${(currentPage ?? 1) - 1}`
  const nextPagePath = `${pathWithoutPage}/${(currentPage ?? 1) + 1}`
  const result: PaginationSEOLink[] = []

  if (!isFirstPage) {
    result.push({
      rel: 'prev',
      href: baseUrl ? baseUrl + prevPagePath : prevPagePath,
    })
  }

  if (!isLastPage) {
    result.push({
      rel: 'next',
      href: baseUrl ? baseUrl + nextPagePath : nextPagePath,
    })
  }

  return result
}
