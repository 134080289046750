import { BrinkCartRepository } from 'repositories/BrinkCartRepository'
import { BrinkPaymentRepository } from 'repositories/BrinkPaymentRepository'
import { XOProductRepository } from 'repositories/XOProductRepository'
import { StoryblokContentRepository } from 'repositories/StoryblokContentRepository'
import { getTenant } from 'helpers/tenant'
import { price } from 'lib/price'
import type { Pinia } from 'pinia'
import { XOSearchRepository } from '~~/repositories/XOSearchRepository'
import type { Storefront } from '~/types/models/storefront'

/**
 * This plugin is used to initialize the repositories which do not need to access the store.
 */
export default defineNuxtPlugin((nuxtApp) => {
  const $currentStorefront = nuxtApp.$currentStorefront as Storefront
  const $pinia = nuxtApp.$pinia as Pinia

  const { brinkApiUrl, XOEnvironment, XOSearchUrl }
    = useRuntimeConfig().public

  const headers = useRequestHeaders()
  const route = useRoute()

  const cacheControl = 'bust' in route.query ? 'no-cache' : 'undefined'

  const inStoryblokPreview = process.server
    ? headers.referer?.includes('app.storyblok.com')
    : document.cookie.includes('storyblokBridge')

  const cacheControlStoryblokContent = inStoryblokPreview ? 'no-cache' : cacheControl
  const tenant = getTenant($currentStorefront.storefrontCode, XOEnvironment, {
    tenantKey: $currentStorefront.tenantKey,
  })

  const productStore = useProductStore($pinia)
  productStore.setWidget()

  return {
    provide: {
      // storeCode: context.$currentStorefront.storefrontCode,
      // TODO: MOVE THIS SOMEWHERE ELSE
      price,
      // gtm: context.gtm,
      storyblok: StoryblokContentRepository({
        baseURL: `/api/storyblok/cdn`,
        storefront: $currentStorefront,
        cacheControl: cacheControlStoryblokContent,
      }),
      cart: BrinkCartRepository({
        baseURL: brinkApiUrl,
        cacheControl,
      }),
      payments: BrinkPaymentRepository({
        baseURL: brinkApiUrl,
        storefront: $currentStorefront,
        cacheControl,
        piniaInstance: $pinia,
      }),
      products: XOProductRepository({
        baseURL: '/api/products',
        tenant,
        cacheControl,
      }),
      facets: XOSearchRepository({
        baseURL: XOSearchUrl,
        tenant,
        cacheControl,
      }),
    },
  }
})
